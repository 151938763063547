import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import AboutUsContent from '../components/AboutUs/AboutUsContent'

import { useIntl } from "react-intl"
import OurHistory from '../components/AboutUs/OurHistory'

const AboutUs = () => {
    const intl = useIntl()
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle={intl.formatMessage({ id: "aboutus" })} 
                homePageText={intl.formatMessage({ id: "home" })} 
                homePageUrl="/" 
                activePageText={intl.formatMessage({ id: "aboutus" })} 
            />
            <AboutUsContent />
            <OurHistory />
            {/*<WhyChooseUs />
            <HowItWork />
            <TeamMembers />
            <Testimonials />
            <Partner />*/}
            <Footer />
        </Layout>
    );
}

export default AboutUs;